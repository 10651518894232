import React from "react";

import SEO from "../components/seo";

const HelloWorld = () => (
    <div>
        <SEO title="Hello World from Evren Tan, the Software Crafter"/>
        <div className="hello-world blink-me">
            <div>
                <h1>Hello World from Evren Tan, the Software Crafter!!</h1>
            </div>
        </div>
    </div>
);

export default HelloWorld;
